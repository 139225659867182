import { z } from 'zod';
import { MfaFactor } from './sign-in-experience.js';
export const roleNamesGuard = z.string().array();
const identityGuard = z.object({
    userId: z.string(),
    details: z.object({}).optional(), // Connector's userinfo details, schemaless
});
export const identitiesGuard = z.record(identityGuard);
export const baseMfaVerification = {
    id: z.string(),
    createdAt: z.string(),
    lastUsedAt: z.string().optional(),
};
export const mfaVerificationTotp = z.object({
    type: z.literal(MfaFactor.TOTP),
    ...baseMfaVerification,
    key: z.string(),
});
export const webAuthnTransportGuard = z.enum([
    'usb',
    'nfc',
    'ble',
    'internal',
    'cable',
    'hybrid',
    'smart-card',
]);
export const mfaVerificationWebAuthn = z.object({
    type: z.literal(MfaFactor.WebAuthn),
    ...baseMfaVerification,
    credentialId: z.string(),
    publicKey: z.string(),
    transports: webAuthnTransportGuard.array().optional(),
    counter: z.number(),
    agent: z.string(),
});
export const mfaVerificationBackupCode = z.object({
    type: z.literal(MfaFactor.BackupCode),
    ...baseMfaVerification,
    codes: z.object({ code: z.string(), usedAt: z.string().optional() }).array(),
});
export const mfaVerificationGuard = z.discriminatedUnion('type', [
    mfaVerificationTotp,
    mfaVerificationWebAuthn,
    mfaVerificationBackupCode,
]);
export const mfaVerificationsGuard = mfaVerificationGuard.array();
