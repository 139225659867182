const action = {
    sign_in: 'サインイン',
    continue: '続ける',
    create_account: 'アカウントを作成する',
    create_account_without_linking: 'リンクなしでアカウントを作成する',
    create: '作成する',
    enter_passcode: '認証コードを入力してください',
    confirm: '確認する',
    cancel: 'キャンセル',
    save_password: 'パスワードを保存する',
    bind: '{{address}} とリンクする',
    bind_and_continue: 'リンクして続行する',
    back: '戻る',
    nav_back: '戻る',
    nav_skip: 'スキップ',
    agree: '同意する',
    got_it: 'わかりました',
    sign_in_with: '{{name}} で続ける',
    forgot_password: 'パスワードを忘れましたか？',
    switch_to: '{{method}} に切り替える',
    sign_in_via_passcode: '認証コードでサインインする',
    sign_in_via_password: 'パスワードでサインインする',
    change: '{{method}} を変更する',
    link_another_email: '別のメールアドレスをリンクする',
    link_another_phone: '別の電話番号をリンクする',
    link_another_email_or_phone: '別のメールアドレスまたは電話番号をリンクする',
    show_password: 'パスワードを表示する',
    copy: 'コピー',
    verify_via_passkey: 'パスキー経由で確認',
    download: 'ダウンロード',
};
export default Object.freeze(action);
