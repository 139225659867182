import { z } from 'zod';
import { OrganizationRoles, Organizations, Users, } from '../db-entries/index.js';
export const organizationRoleWithScopesGuard = OrganizationRoles.guard.extend({
    scopes: z
        .object({
        id: z.string(),
        name: z.string(),
    })
        .array(),
});
const organizationRoleEntityGuard = z.object({
    id: z.string(),
    name: z.string(),
});
export const organizationWithOrganizationRolesGuard = Organizations.guard.extend({
    organizationRoles: organizationRoleEntityGuard.array(),
});
export const userWithOrganizationRolesGuard = Users.guard.extend({
    organizationRoles: organizationRoleEntityGuard.array(),
});
