const action = {
    sign_in: 'Accedi',
    continue: 'Continua',
    create_account: 'Crea account',
    create_account_without_linking: 'Crea account senza collegare',
    create: 'Crea',
    enter_passcode: 'Inserisci il codice di verifica',
    confirm: 'Conferma',
    cancel: 'Annulla',
    save_password: 'Salva password',
    bind: 'Collega con {{address}}',
    bind_and_continue: 'Collega e continua',
    back: 'Torna indietro',
    nav_back: 'Indietro',
    nav_skip: 'Salta',
    agree: 'Accetto',
    got_it: 'Capito',
    sign_in_with: 'Continua con {{name}}',
    forgot_password: 'Hai dimenticato la password?',
    switch_to: 'Passa a {{method}}',
    sign_in_via_passcode: 'Accedi con il codice di verifica',
    sign_in_via_password: 'Accedi con la password',
    change: 'Cambia {{method}}',
    link_another_email: 'Collega un altro indirizzo email',
    link_another_phone: 'Collega un altro numero di telefono',
    link_another_email_or_phone: 'Collega un altro indirizzo email o numero di telefono',
    show_password: 'Mostra password',
    copy: 'Copia',
    verify_via_passkey: 'Verifica tramite passkey',
    download: 'Scarica',
};
export default Object.freeze(action);
