// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { jsonObjectGuard } from './../foundations/index.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    userId: z.string().min(1).max(12),
    issuer: z.string().min(1).max(256),
    identityId: z.string().min(1).max(128),
    detail: jsonObjectGuard.optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    userId: z.string().min(1).max(12),
    issuer: z.string().min(1).max(256),
    identityId: z.string().min(1).max(128),
    detail: jsonObjectGuard,
    createdAt: z.number(),
});
export const UserSsoIdentities = Object.freeze({
    table: 'user_sso_identities',
    tableSingular: 'user_sso_identity',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        userId: 'user_id',
        issuer: 'issuer',
        identityId: 'identity_id',
        detail: 'detail',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'userId',
        'issuer',
        'identityId',
        'detail',
        'createdAt',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
