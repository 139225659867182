const action = {
    sign_in: 'Entrar',
    continue: 'Continuar',
    create_account: 'Criar conta',
    create_account_without_linking: 'Criar conta sem vincular',
    create: 'Criar',
    enter_passcode: 'Inserir código de verificação',
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    save_password: 'Salvar senha',
    bind: 'Link com {{address}}',
    bind_and_continue: 'Linkar e continuar',
    back: 'Voltar',
    nav_back: 'Voltar',
    nav_skip: 'Pular',
    agree: 'Aceito',
    got_it: 'Entendido',
    sign_in_with: 'Continuar com {{name}}',
    forgot_password: 'Esqueceu sua senha?',
    switch_to: 'Trocar para {{method}}',
    sign_in_via_passcode: 'Fazer login com código de verificação',
    sign_in_via_password: 'Fazer login com senha',
    change: 'Alterar {{method}}',
    link_another_email: 'Vincular outro e-mail',
    link_another_phone: 'Vincular outro telefone',
    link_another_email_or_phone: 'Vincular outro e-mail ou telefone',
    show_password: 'Mostrar senha',
    copy: 'Copiar',
    verify_via_passkey: 'Verificar via chave de acesso',
    download: 'Baixar',
};
export default Object.freeze(action);
